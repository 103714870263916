export const LOCATION = 'location';
export const SAMPLE_ID = 'sampleId';
export const SCREENING_ID = 'screeningId';
export const IS_INERRUPTED = 'isInterrupted';
export const SCREENING_CODE = 'screeningCode';
export const STATUS = 'screeningProcessStatusId';
export const STATUS_LIST = 'statusList';
export const ANALYSIS_DATE = 'analysisDate';
export const MUNICIPALITY_ID = 'municipalityId';
export const FIRST_NAMES = 'firstNames';
export const SURNAME = 'surname';
export const SSN = 'ssn';
export const CONTROL_ID = 'controlId';
export const CONTROL_CODE = 'controlCode';
export const SCREENING_YEAR = 'screeningYear';
export const SAMPLE_DATE = 'sampleDate';
export const FULL_NAME = 'fullname';
export const FIRSTNAME = 'firstName';
export const LASTNAME = 'lastName';
export const COMMENT = 'comment';
export const ORGANIZATION = 'organization';
export const NUMBER_POLYP_ID = 'numberOfPolypsId';
export const HANDLER_LIST = 'handlerList';
export const ALLOWED_HANDLER_LIST = 'allowedHandlerList';
export const LARGEST_POLY_DIAMETER_ID = 'largestPolypDiameterId';
export const ADITIONAL_INFO = 'additionalInfo';
export const SEARCH_KEYS = 'Search-Keys';
export const COULD_NOT_BE_REACHED_AT = 'couldNotBeReachedAt';
export const PAGE_NUMBER = 'Page-Number';
